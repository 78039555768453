import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import lscache from 'lscache'
import countryToCurrency from 'country-to-currency'
import uuid from 'uuid-random'

import api from '@/api'
import config from '../config'
import Jet from '@/modules/Jet'
import Sentry from '@/modules/Sentry'
import { ENV_DEV_OR_STAGING, ENV_HACKERONE } from '@/helpers/environment'
import { SUPPORTED_CURRENCIES, GDPR_COUNTRIES, LS_EXPIRATION_MINUTES } from '@/helpers/constants'
import request, { marketingRequest, paymentApiAxiosInstance as paymentApi } from '@/helpers/request'
import coreFeatures from '@/store/coreFeatures'
import { bmiCalculator } from '@/helpers/userData'
import { filterInvalidKeys } from '@/helpers/utils'
import { isSubscriptionProductExistInList } from '@/helpers/products'

import i18n from './i18n.js'
import survey from './survey.js'
import location from './location.js'
import status from './status.js'
import userFlags from './userFlags.js'
import onetrust from './onetrust.js'
import paywall from './paywall.js'
import { palta, convertPaltaSubscription } from '@/modules/Palta'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		onetrust,
		survey,
		i18n,
		location,
		status,
		coreFeatures,
		userFlags,
		palta,
		paywall,
	},

	plugins: [
		createPersistedState({
			key: 'vuex', // Store this data in localStorage for 1 week (see LS_EXPIRATION_MINUTES)
			storage: {
				getItem: (key) => lscache.get(key),
				setItem: (key, value) => lscache.set(key, value, LS_EXPIRATION_MINUTES),
				removeItem: (key) => lscache.remove(key),
			},
			paths: [
				'absmartly',
				'marketingDeduplicationEventId',
				'location',
				'paywall',
				'survey',
				'userFlags.local',
				'email',
				'main_user_id',
			],
		}),
		createPersistedState({
			key: 'auth', // Store this data in localStorage forever
			storage: {
				getItem: (key) => lscache.get(key),
				setItem: (key, value) => lscache.set(key, value),
				removeItem: (key) => lscache.remove(key),
			},
			paths: ['auth', 'location.initialUtmParams', 'auth0'],
		}),
		createPersistedState({
			storage: window.sessionStorage,
			paths: [
				'geo',
				'name',
				'zip',
				'dateEnd',
				'subscription',
				'upsaled',
				'countdown',
				'countdownFinishedAt',
				'lastEmailSendAt',
				'promocode',
				'currency',
				'tax',
				'palta',
				'organicTrafficOptions',
				'loginCredentials',
				'appArea',
				'hasPrevSurvey',
				'signedIn',
			],
		}),
	],

	state: {
		auth: {
			token: null,
			refreshToken: null,
			expireAt: null,
		},
		auth0: {
			accessToken: null,
			idToken: null,
			expiresAt: null,
		},
		absmartly: {
			exposures: {},
		},
		promocode: null,
		countdown: null,
		countdownIsVisible: false,
		countdownFinishedAt: null,
		notification: null,
		subscription: null,
		email: '',
		name: '',
		zip: '',
		lastEmailSendAt: null,

		user: null,
		main_user_id: null,

		currency: 'usd',
		dateEnd: null,
		upsaled: false,
		hasWorkbookPurchaseError: false,
		geo: null,
		tax: null,
		marketingDeduplicationEventId: uuid(),
		appTheme: null,
		organicTrafficOptions: {
			paymentConsent: false,
			paymentConsentError: false,
			upgradeConsent: false,
			upgradeConsentError: false,
		},
		loginCredentials: {
			email: null,
			code: null,
			sentAt: null,
			attempts: 0,
			mode: 'default',
		},
		appArea: null,
		hasPrevSurvey: false,
		signedIn: false,
	},

	getters: {
		getAuthCredentials(state) {
			return state.auth
		},

		getAuth0Credentials(state) {
			return state.auth0
		},

		isAnonymous(state) {
			return state.user && state.user.isAnonymous
		},

		isAuthenticated(state) {
			return state.user && state.user.isThirdParty
		},

		isOnboarded(state) {
			return state.user && state.user.isOnboarded
		},

		getMeasurementSystem: () => (type) => {
			return ['lbs', 'ft'].includes(type) ? 'imperial' : 'metric'
		},

		getHeightUnitSystem(state, getters) {
			let value = getters['survey/getPropertyValue']('measurement_system_height', true)
			// Set defaults
			if (!value) {
				if (getters.getCountryCode === 'us') {
					value = 'ft'
				} else {
					value = 'cm'
				}
			}
			return value
		},

		getWeightUnitSystem(state, getters) {
			let value = getters['survey/getPropertyValue']('measurement_system_weight', true)
			if (Array.isArray(value)) {
				value = value.toString()
			}

			// Set defaults
			if (!value) {
				const heightUnitSystem = getters['survey/getPropertyValue']('measurement_system_height', true)

				if (heightUnitSystem === 'ft') {
					return 'lbs'
				} else if (heightUnitSystem === 'cm') {
					return 'kg'
				}

				if (getters.getCountryCode === 'us') {
					return 'lbs'
				} else if (getters.getCountryCode === 'gb') {
					return 'st'
				} else {
					return 'kg'
				}
			}

			return value
		},

		getBmi(state, getters) {
			const weight = getters['survey/getPropertyValue']('weight', false)
			const height = getters['survey/getPropertyValue']('height', false)
			if (!weight || !height) {
				return null
			}
			return bmiCalculator(weight, height)
		},

		subscriptionIsActive(state) {
			return state.subscription && !state.subscription.cancelled
		},

		getUserId(state) {
			return state.main_user_id || state.user?.id
		},

		hasAlreadyExistedAccount(state) {
			return state.main_user_id && state.user?.id && state.main_user_id !== state.user?.id
		},

		getUserEmail(state) {
			return state.email || state.user?.email
		},

		getUserName(state) {
			if (state.user?.name === 'Anonymous') {
				return ''
			}

			return state.user?.name ?? ''
		},

		getCountryCode(state) {
			return state.geo?.countryCode
		},

		getCountryCodeUppercase(state) {
			return state.geo?.countryCode?.toUpperCase()
		},

		getGeo(state) {
			return state.geo ?? {}
		},

		getGdprStatus(state) {
			return state.geo?.gdpr
		},

		getIsEUCountry(state) {
			return state.geo?.isEUCountry
		},

		getCurrency(state) {
			return state.currency
		},

		getCurrencyUppercase(state) {
			return state.currency.toUpperCase()
		},

		getAbSmartlyExposures(state) {
			return Object.values(state.absmartly.exposures)
		},
		getAppTheme(state) {
			return state.appTheme ?? 'light'
		},
		getIsCheapAndShortTrial(state) {
			const isCheapAndShortStripe = [config('stripe_cheap_and_short')].includes(state.subscription?.product_id)

			const isCheapAndShortBraintree = [
				'pro_short_cheap-month-1-usd-subscription',
				'pro_short_cheap-month-3-usd-subscription',
			].includes(state.subscription?.product_id)

			const isCheapAndShortPalta = isSubscriptionProductExistInList(
				['SIMPLE_PAYWALL_1M_29_99_1W_4_99', 'SIMPLE_PAYWALL_1M_29_99_1M_17_99', 'SIMPLE_PAYWALL_3M_59_99_3M_29_99'],
				state.subscription,
			)

			return [isCheapAndShortStripe, isCheapAndShortBraintree, isCheapAndShortPalta].some(Boolean)
		},
		getIsCheapAndShortSubscription(state) {
			const isCheapAndShortStripe = [
				config('stripe_cheap_and_short'),
				config('stripe_cheap_and_short_upgrade_1_year'),
				config('stripe_cheap_and_short_upgrade_3_month'),
				config('stripe_cheap_and_short_upgrade_6_month'),
				config('stripe_cheap_and_short_upgrade_9_month'),
			].includes(state.subscription?.product_id)

			const isCheapAndShortBraintree = state.subscription?.product_id?.startsWith('pro_short_cheap')

			const isCheapAndShortPalta = isSubscriptionProductExistInList(
				[
					'SIMPLE_UPGRADE_3M_59',
					'SIMPLE_UPGRADE_3M_47_2',
					'SIMPLE_UPGRADE_6M_79',
					'SIMPLE_UPGRADE_6M_63_2',
					'SIMPLE_UPGRADE_9M_99',
					'SIMPLE_UPGRADE_9M_79_2',
				],
				state.subscription,
			)

			return [isCheapAndShortStripe, isCheapAndShortBraintree, isCheapAndShortPalta].some(Boolean)
		},
		// https://simple-app.atlassian.net/wiki/spaces/BCS/pages/387514394/Product+Plans+for+new+Experiments
		getCommitmentPlanPeriod(state) {
			if (state.subscription?.commitment_period) {
				return state.subscription?.commitment_period
			}

			const commitmentPeriodsMap = {
				// Stripe
				[config('stripe_commitment_3_month')]: 3,
				[config('stripe_commitment_6_month')]: 6,
				[config('stripe_commitment_10_month')]: 10,

				// Braintree
				'pro_commitment-month-3-usd-subscription': 3,
				'pro_commitment-month-6-usd-subscription': 6,
				'pro_commitment-month-10-usd-subscription': 10,

				// Palta
				simple_premium_1m_15_1m_15_usd: 3,
				simple_premium_1m_10_1m_10_usd: 10,
				simple_premium_1m_9_99_1m_9_99_usd: 10,
				simple_premium_comm_1m_29_99_3m_15_usd: 3,
				simple_premium_comm_1m_29_99_10m_10_usd: 10,
			}

			if (!commitmentPeriodsMap[state.subscription?.product_id]) {
				return null
			}

			return commitmentPeriodsMap[state.subscription?.product_id]
		},
		getOrganicTrafficOptions(state) {
			return state.organicTrafficOptions
		},
		getAppArea(state) {
			return state.appArea
		},

		isSignedIn(state) {
			return state.signedIn
		},
	},

	mutations: {
		setUser(state, user) {
			state.user = user
		},

		setAuthCredentials(state, { token, refreshToken, expireAt }) {
			state.auth.token = token
			state.auth.refreshToken = refreshToken
			state.auth.expireAt = expireAt
		},

		setAuth0Credentials(state, { accessToken, idToken, expiresAt }) {
			state.auth0.accessToken = accessToken
			state.auth0.idToken = idToken
			state.auth0.expiresAt = expiresAt
		},

		addAbSmartlyExposure(state, exposure) {
			const { name, iteration, variantLetter, variantName } = exposure
			const key = `${name}_${iteration}`
			const exposureData = { name, iteration, variantLetter, variantName }

			Vue.set(state.absmartly.exposures, key, exposureData)
		},

		removeAbSmartlyExposure(state, { name, iteration }) {
			Vue.delete(state.absmartly.exposures, `${name}_${iteration}`)
		},

		markOnboarded(state) {
			if (state.user) {
				state.user.isOnboarded = true
			}
		},

		setSubscription(state, subscription) {
			if (!subscription) {
				state.subscription = null
				return
			}

			let { schedule_subscription, ...originalSubscriptionData } = subscription ?? {}

			if (schedule_subscription) {
				// eslint-disable-next-line no-unused-vars
				const { created_at, ...scheduledSubscriptionData } = schedule_subscription

				originalSubscriptionData = {
					...originalSubscriptionData,
					...scheduledSubscriptionData,
					cancelled: false,
				}
			}
			state.subscription = originalSubscriptionData
		},

		resetNotification(state) {
			state.notification = null
		},

		setNotification(state, message) {
			state.notification = message
		},

		saveEmail(state, value) {
			state.email = value
		},

		saveName(state, value) {
			state.name = value
		},

		saveZip(state, value) {
			state.zip = value
		},

		updateCountdown(state, value) {
			state.countdown = value
		},

		toggleCountdown(state, value) {
			state.countdownIsVisible = value
		},

		setCountdownFinishedAt(state, finishedAt) {
			state.countdownFinishedAt = finishedAt
		},

		updateLastEmailSendAt(state, value) {
			state.lastEmailSendAt = value
		},

		subscriptionMarkCanceled(state) {
			state.subscription.cancelled = true
		},

		setPromocode(state, promocode) {
			state.promocode = promocode
		},

		setCurrency(state, currency) {
			state.currency = currency
		},

		saveDateEnd(state, date) {
			state.dateEnd = date
		},

		setUpsaled(state, upsaled) {
			state.upsaled = upsaled
		},

		setMainUserId(state, user_id) {
			state.main_user_id = user_id
		},

		setWorkbookPurchaseError(state, value) {
			state.hasWorkbookPurchaseError = value
		},

		setGeo(state, geo) {
			state.geo = geo
		},

		setTax(state, tax) {
			state.tax = tax
		},

		setAppTheme(state, theme) {
			state.appTheme = theme
		},

		setOrganicTrafficOption(state, { key, value } = {}) {
			state.organicTrafficOptions[key] = value
		},

		setLoginCredentials(state, data) {
			state.loginCredentials = data
		},

		setAppArea(state, value) {
			state.appArea = value
		},

		setHasPrevSurvey(state, value) {
			state.hasPrevSurvey = value
		},

		setSignedIn(state) {
			state.signedIn = true
		},
	},

	actions: {
		async setUserData(store, user) {
			store.commit('setUser', user)

			let userId = user.id

			const isCrmAutologin = store.getters['location/isCrmAutologin']

			if (isCrmAutologin) {
				const userIdFromQueryParam = store.getters['location/getQueryParam']('user')
				const userEmailFromQueryParam = store.getters['location/getQueryParam']('email')
				const userHeightFromQueryParam = store.getters['location/getQueryParam']('userHeight')
				const userWeightFromQueryParam = store.getters['location/getQueryParam']('userWeight')
				const userTargetWeightFromQueryParam = store.getters['location/getQueryParam']('userTargetWeight')
				const userAgeFromQueryParam = store.getters['location/getQueryParam']('userAge')
				const userSexFromQueryParam = store.getters['location/getQueryParam']('userSex')

				if (uuid.test(userIdFromQueryParam)) {
					userId = userIdFromQueryParam
					store.commit('setMainUserId', userIdFromQueryParam)
				}

				if (userEmailFromQueryParam) {
					store.commit('saveEmail', userEmailFromQueryParam)
				}

				if (userHeightFromQueryParam) {
					store.commit('survey/updateProperty', { property: 'height', value: parseInt(userHeightFromQueryParam) })
				}

				if (userWeightFromQueryParam) {
					store.commit('survey/updateProperty', { property: 'weight', value: parseInt(userWeightFromQueryParam) })
				}

				if (userTargetWeightFromQueryParam) {
					store.commit('survey/updateProperty', {
						property: 'targetWeight',
						value: parseInt(userTargetWeightFromQueryParam),
					})
				}

				if (userAgeFromQueryParam) {
					store.commit('survey/updateProperty', {
						property: 'age',
						value: parseInt(userAgeFromQueryParam),
						isData: true,
					})
				}

				if (userSexFromQueryParam) {
					store.commit('survey/updateProperty', { property: 'sex', value: userSexFromQueryParam })
				}
			}

			this._vm.$analytic.setUserId(userId)
			Jet.setUser({ userId: userId })
			window.dataLayer?.push({ user_id: userId })
			Sentry.setUser()
		},

		async fetchUserFromAws(store) {
			try {
				if (store.getters['status/resourceIsPending']('user')) {
					return
				}

				store.commit('status/setResourcePending', 'user')

				const response = await api.getUser()

				await store.dispatch('setUserData', response.data)

				await store.dispatch('loadSubscription')

				if (response.data.data) store.dispatch('mergeUserData')
			} catch (e) {
				this._vm.$analytic.logEvent('Get user error', { e })
			} finally {
				store.commit('status/unsetResourcePending', 'user')
			}
		},

		async loadSubscription(store) {
			try {
				const userId = store.getters['getUserId']
				const response = await paymentApi.get(`v2/api/users/${userId}/subscription`)
				const subscriptionData = response?.data || {}

				const subscription = Object.keys(subscriptionData).length > 0 ? subscriptionData : null

				if (subscription || subscription?.user_id !== userId) {
					store.commit('setSubscription', subscription)
				}
			} catch (e) {
				store.commit('setSubscription', null)
				this._vm.$analytic.logEvent('Get subscription error', {
					e,
				})
				// Sentry.captureException(e)
			}
		},

		loadPaltaSubscription(store, params = {}) {
			return store
				.dispatch('palta/fetchSubscriptions', {
					user_id: store.getters['getUserId'],
					...params,
				})
				.then(({ subscription, status }) => {
					if (subscription) {
						store.commit('setSubscription', convertPaltaSubscription(subscription, store.getters['getUserId']))
					}
					return {
						status,
						subscription: store.state.subscription,
					}
				})
		},

		switchPaltaSubscription(store, params) {
			return store.dispatch('palta/switchSubscription', params).then((result) => {
				const { schedule_subscription } = result

				const subscription = schedule_subscription ? { ...store.state.subscription, schedule_subscription } : result

				store.commit('setSubscription', subscription)
				return subscription
			})
		},

		makePaltaPurchase(store, params) {
			return store.dispatch('palta/purchase', params).then((result) => {
				return result.data
			})
		},

		async checkExistingUserByEmail(store, params) {
			const response = await api.userCheck(params)
			const userId = response.data

			store.commit('setMainUserId', userId)

			await store.dispatch('loadSubscription')

			return userId
		},

		async sendConfirmEmail(store, params) {
			await api.sendConfirmEmail({
				email: store.state.email,
				...params,
			})
			store.commit('updateLastEmailSendAt', new Date())
		},

		createPaymentCustomer(store) {
			let email = store.getters['getUserEmail']
			let name = store.getters['getUserName']
			let user_id = store.getters['getUserId']
			let abSmartlyExposures = store.getters['getAbSmartlyExposures']

			fetch(`${config('SimpleAPIUrl')}v1/api/customers`, {
				method: 'POST',
				body: JSON.stringify({
					user_id,
					email,
					name,
					experiments: abSmartlyExposures,
				}),
			}).catch((error) => {
				console.error(error.response)
			})
		},

		/**
		 * Method for sending marketing campaign data to Payment API
		 * @param store
		 * @param {object} data
		 * @param {'facebook'|'google'|'impact'|'twitter'} data.source - source of campaign
		 * @param {object} data.params
		 */
		sendMarketingCampaignData(store, data) {
			const { source, params } = data

			return fetch(`${config('SimpleAPIUrl')}v1/api/campaign-tracker/${source}`, {
				method: 'POST',
				body: JSON.stringify(params),
			})
				.then(() => {
					if (ENV_DEV_OR_STAGING) {
						/* eslint-disable no-console */
						console.groupCollapsed(
							`%cMarketing campaign tracker [debug]: %c${source}`,
							'font-weight: 400',
							'color: #19ffb2',
						)
						console.log(data)
						console.groupEnd()
						/* eslint-enable no-console */
					}
				})
				.catch((error) => {
					Sentry.withScope((scope) => {
						scope.setExtras({ source, ...params })
						scope.setTags({
							marketingCampaignTrackerError: true,
						})
						scope.captureException(error)
					})
				})
		},

		async createMeasurement(store) {
			return await api.createMeasurement({
				records: [
					{
						type: 'Weight',
						value: store.state.user.weight,
						source: 'Manual',
						date: new Date(),
						secondsFromGMT: -new Date().getTimezoneOffset() * 60,
					},
				],
			})
		},

		/**
		 * Get country code and currency from https://assets.simple.org/geo.json
		 * @returns {Promise<{countryCode: String, currency: String}>}
		 */
		async fetchSimpleGeo(store, { countryOverride }) {
			return request(config('GeoAPIUrl')).then((response) => {
				let { city, continent, country, isEUCountry, region, regionCode, postalCode } = response

				if (countryOverride) {
					country = countryOverride
				}

				const result = {
					countryCode: country.toLowerCase(),
					currency: countryToCurrency[country],
					continentCode: continent,
					city: city?.toLowerCase(),
					isEUCountry: isEUCountry === '1',
					regionName: region?.toLowerCase(),
					region: regionCode?.toLowerCase(),
					zip: postalCode,
				}

				return filterInvalidKeys(result)
			})
		},

		async fetchIpWhoIsGeo() {
			return request('https://ipwho.is/')
		},

		/**
		 * Fetch geo data and set it to the store
		 * @param store
		 * @returns {Promise<void>}
		 */
		async fetchGeo(store, { country }) {
			let currencyCode = 'usd'

			if (store.state.geo && !country) {
				return
			}

			if (ENV_DEV_OR_STAGING || ENV_HACKERONE) {
				const queryParamCountry = country || store.getters['location/getQueryParam']('country')
				if (queryParamCountry && /^[A-Z]{2}$/.test(queryParamCountry)) {
					country = queryParamCountry

					store.commit('setCurrency', currencyCode)
					store.commit('setGeo', {
						countryCode: country.toLowerCase(),
						currency: countryToCurrency[country],
						gdpr: GDPR_COUNTRIES.includes(country),
					})
				}
			}

			const simpleGeoResult = await store.dispatch('fetchSimpleGeo', { countryOverride: country })

			if (!simpleGeoResult) {
				return
			}

			if (SUPPORTED_CURRENCIES.includes(simpleGeoResult.currency)) {
				currencyCode = simpleGeoResult.currency?.toLowerCase()
			}

			store.commit('setCurrency', currencyCode)
			store.commit('setGeo', {
				...simpleGeoResult,
				gdpr: GDPR_COUNTRIES.includes(simpleGeoResult.countryCode?.toUpperCase()),
			})
		},

		async fetchUserLocalTax(store) {
			const params = {
				user_id: store.getters['getUserId'],
				geo_data: store.state.geo,
			}

			return await fetch(`${config('SimpleAPIUrl')}v1/api/geodata`, {
				method: 'POST',
				body: JSON.stringify(params),
			})
				.then((response) => response.json())
				.then((data) => {
					store.commit('setTax', data.web_rate)

					return data.web_rate
				})
				.catch((error) => {
					Sentry.withScope((scope) => {
						scope.setExtras({ params, queryParams: store.state.location.queryParams })
						scope.setTags({
							paymentApiError: true,
						})
						scope.captureException(error)
					})
				})
		},

		logout() {
			localStorage.clear()
			window.location.href = '/'
		},

		mergeUserData({ state }) {
			let user = state.user
			let data = user.data ? JSON.parse(user.data) : {}

			Object.keys(state.user)
				.filter((key) => key in state.survey.values)
				.forEach((key) => {
					if (key == 'data') {
						Object.keys(data)
							.filter((data_key) => data_key in state.survey.values.data)
							.forEach((data_key) => {
								state.survey.values.data[data_key] = data[data_key] === 'null' ? null : data[data_key]
							})
					} else {
						const surveyValues = state.survey.values[key]
						const userValues = state.user[key]

						if (Array.isArray(surveyValues) && Array.isArray(userValues)) {
							const uniqueValues = Array.from(new Set([...surveyValues, ...userValues]))
							state.survey.values[key] = uniqueValues
						} else {
							state.survey.values[key] = userValues
						}
					}
				})
			// Fix for users who came from mobile onboarding which does not
			// set measurement_system_height and measurement_system_weight
			// and instead using measurement_system for both height and weight
			// doesn't change already set value
			const msHeight = state.survey.values.data.measurement_system_height
			const msWeight = state.survey.values.data.measurement_system_weight
			if (state.survey.values.data.measurement_system && (!msHeight || !msWeight)) {
				const isMetric = state.survey.values.data.measurement_system?.toString() === 'metric'
				state.survey.values.data.measurement_system_height = msHeight || (isMetric ? 'cm' : 'ft')
				state.survey.values.data.measurement_system_weight = msWeight || (isMetric ? 'kg' : 'lbs')
			}
		},

		async setPromocodeByName({ commit }, promocode) {
			try {
				const { data } = await axios.get(`${config('SimpleAPIUrl')}v1/api/marketing/promocode`, {
					params: {
						promocode,
					},
				})

				// TODO: We need to map new data to old models in order to support existing implementations
				const oldPromocodeModel = {
					id: data.code,
					label: data.label,
					trial: data.trial,
					trial_price: data.trial_price,
					trial_percent: data.trial_percent,
					discount: Boolean(data.percent),
					discount_value: data.percent,
				}
				commit('setPromocode', oldPromocodeModel || null)
			} catch (e) {
				commit('setPromocode', null)
				throw e
			}
		},

		async activateFreebieAccess({ getters }, freebieCode) {
			return await request(`${config('SimpleAPIUrl')}v1/api/free-access/activate`, {
				method: 'POST',
				data: {
					code: freebieCode,
					user_id: getters.getUserId,
				},
			})
		},

		sendAbSmartlyExposuresToBraze(store, exposures) {
			if (!exposures) {
				exposures = store.state.absmartly.exposures
			}

			for (let exposureName in exposures) {
				const { name, iteration, variantLetter, variantName } = exposures[exposureName]
				const underscoredName = name.replaceAll(' ', '_')

				this._vm.$appboy.logCustomEvent('Experiment - Exposure', {
					Name: name,
					Iteration: `i${iteration}`,
					Variant: variantLetter,
					VariantName: variantName,
				})
				this._vm.$appboy.addToCustomAttributeArray(
					'active_test_groups_web',
					`${underscoredName}(${variantLetter})(${`i${iteration}`})`,
				)
			}
		},

		async updateCustomerInfo({ getters }, options) {
			return await paymentApi.patch(`v1/customers/${getters.getUserId}`, { ...options })
		},

		async saveUserMarketingParams(context, params) {
			try {
				await marketingRequest('/actions/user_click', {
					method: 'POST',
					data: params,
				})
			} catch (error) {
				// Just let the user pass anyway
			}
		},

		async sendMarketingPurchaseTrackEvent({ getters }) {
			await marketingRequest('actions/purchase_track/frontend', {
				method: 'put',
				params: {
					user_id: getters.getUserId,
				},
			})
		},
	},
})
