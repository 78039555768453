import axios from 'axios'
import retry, { exponentialDelay, isSafeRequestError } from 'axios-retry'
import Sentry from '@/modules/Sentry'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'

import config from '@/config'

import store from '@/store'
import auth0 from '@/modules/Auth0'

/**
 * Class for creating an instance of axios with custom config
 * https://github.com/axios/axios/tree/v0.x#creating-an-instance
 *
 * @param {string} options.baseUrl - `baseURL` will be prepended to `url` unless `url` is absolute
 */
class RequestClient {
	constructor(options) {
		const defaultOptions = {
			baseURL: '',
			useRetry: true,
			useErrorLog: true,
			throwErrors: true,
		}

		this.options = { ...defaultOptions, ...options }
		this.initAxiosInstance()
	}

	initAxiosInstance() {
		this.instance = axios.create({ baseURL: this.options.baseURL })
		this.setupRetry()
	}

	setupRetry() {
		if (!this.options.useRetry) {
			return
		}

		retry(this.instance, {
			shouldResetTimeout: true,
			retryDelay: exponentialDelay,
			retryCondition: function (error) {
				if (['ECONNABORTED', 'ERR_NETWORK'].includes(error.code)) {
					return true
				}

				// !error.response OR (status >= 500)
				// AND
				// methods: 'get' OR 'head' OR 'options'
				return isSafeRequestError(error)
			},
		})
	}

	onError(error) {
		if (!this.options.useErrorLog) {
			return
		}
		Sentry.withScope((scope) => {
			scope.setExtras({
				data: error.config?.data ? JSON.stringify(error.config.data) : null,
				retries: JSON.stringify(error.config?.['axios-retry'] || {}),
				params: error.config?.params,
			})
			scope.setTags({
				networkErrorType: error.name,
				networkErrorMessage: error.message,
				networkErrorCode: error.code,
				networkErrorStatus: error.request?.status,
				networkErrorMethod: error.config?.method,
				networkErrorBaseUrl: error.config?.baseURL,
				networkErrorUrl: error.config?.url,
				networkErrorResponseStatus: error.response?.status,
			})
			scope.captureMessage('RequestClient onError')
		})

		if (this.options.throwErrors) {
			throw error
		}
	}
}

class JWTRequestClient extends RequestClient {
	constructor(options) {
		super(options)
		this.setupJWTInterceptors()
	}

	setupJWTInterceptors() {
		this.instance.interceptors.request.use(this.requestFulfilled)
		this.instance.interceptors.response.use(null, this.responseRejected)
	}

	requestFulfilled = async (requestConfig) => {
		const token = await this.fetchToken()

		if (token) {
			requestConfig.headers['Authorization'] = `Bearer ${token}`
		}

		return requestConfig
	}

	responseRejected = async (err) => {
		const originalConfig = err.config

		if (err?.response?.status === 401 && !originalConfig._retry && !originalConfig.baseURL.includes('simple.life')) {
			originalConfig._retry = true
			try {
				await this.fetchToken()
				return await this.instance(originalConfig)
			} catch (_error) {
				return Promise.reject(_error)
			}
		}
		return Promise.reject(err)
	}

	validateToken(token, expireAt) {
		return token && expireAt && isAfter(parseISO(expireAt), new Date())
	}

	async refreshOldToken(token, jwtRefreshToken) {
		const headers = {}
		const params = {}

		if (token && jwtRefreshToken) {
			params.refreshToken = jwtRefreshToken
			headers.Authorization = `Bearer ${token}`
		}

		const response = await request(config('FstrAPIUrl') + '/user/token', {
			headers,
			params,
			timeout: 15000,
		})
		const responseData = response?.data ?? {}

		store.commit('setAuthCredentials', {
			token: responseData.token,
			refreshToken: responseData.refreshToken,
			expireAt: responseData.expireAt,
		})
	}

	async refreshAuth0Token() {
		try {
			await auth0.refreshSession()
		} catch (error) {
			const cause = error.cause ?? {}

			Sentry.withScope((scope) => {
				scope.setTags({
					auth0Token: true,
				})
				scope.setExtras({
					...cause,
				})
				scope.captureException(error)
			})
		}
	}

	async fetchToken() {
		const { accessToken, idToken, expiresAt } = store.getters.getAuth0Credentials
		const { token, refreshToken, expireAt } = store.getters.getAuthCredentials

		const hasAuth0Token = accessToken && idToken && expiresAt

		const jwtToken = hasAuth0Token ? accessToken : token
		const jwtExpireAt = hasAuth0Token ? expiresAt : expireAt
		const jwtRefreshToken = hasAuth0Token ? null : refreshToken

		if (this.validateToken(jwtToken, jwtExpireAt)) {
			return jwtToken
		}

		if (hasAuth0Token) {
			await this.refreshAuth0Token()
		} else {
			await this.refreshOldToken(token, jwtRefreshToken)
		}

		return hasAuth0Token ? store.getters.getAuth0Credentials.accessToken : store.getters.getAuthCredentials.token
	}
}

const clients = {
	DEFAULT: new RequestClient({
		baseURL: null,
		throwErrors: false,
	}),
	FSTR_API: new JWTRequestClient({
		baseURL: config('FstrAPIUrl'),
	}),
	FSTR_USERS_API: new JWTRequestClient({
		baseURL: config('FstrUsersAPIUrl'),
	}),
	FSTR_SYNC_API: new JWTRequestClient({
		baseURL: config('FstrSyncAPIUrl'),
	}),
	PAYMENT_API: new JWTRequestClient({
		baseURL: config('SimpleAPIUrl'),
		useRetry: false,
		useErrorLog: false,
	}),
	MARKETING_API: new RequestClient({
		baseURL: config('MarketingAPIUrl'),
	}),
	ONBOARDING_BUILDER_S3: new RequestClient({
		baseURL: config('OnboardingBuilderS3'),
	}),
	ONBOARDING_BUILDER_QA: new RequestClient({
		baseURL: config('OnboardingBuilderQA'),
	}),
	ASSESSMENT_API: new RequestClient({
		baseURL: config('AssessmentAPIUrl'),
	}),
}

/**
 * @param {string} url
 * @param {string} clientName
 * @param {import('axios').Method} method
 * @param {import('axios').AxiosRequestConfig} options
 * @returns {import('axios').AxiosPromise}
 */
const request = async (url, { clientName = 'DEFAULT', method = 'get', ...options } = {}) => {
	const client = clients[clientName] ?? clients.DEFAULT

	return client
		.instance(url, { method, ...options })
		.then((response) => response.data)
		.catch((error) => {
			client.onError(error)
		})
}

export const fstrRequest = (url, options = {}) => {
	return request(url, { clientName: 'FSTR_API', ...options })
}

export const fstrUsersRequest = (url, options = {}) => {
	return request(url, { clientName: 'FSTR_USERS_API', ...options })
}

export const fstrSyncRequest = (url, options = {}) => {
	return request(url, { clientName: 'FSTR_SYNC_API', ...options })
}

export const paymentApiAxiosInstance = clients.PAYMENT_API.instance

export const onboardingBuilderAxiosInstance = clients.ONBOARDING_BUILDER_S3.instance

export const onboardingBuilderQaAxiosInstance = clients.ONBOARDING_BUILDER_QA.instance

export const assessmentRequest = (url, options = {}) => {
	return request(url, { clientName: 'ASSESSMENT_API', ...options })
}

/**
 * @param {string} url
 * @param {import('axios').AxiosRequestConfig} options
 * @returns {import('axios').AxiosPromise}
 */
export const marketingRequest = (url, options = {}) => {
	return request(url, { clientName: 'MARKETING_API', ...options })
}

export default request
