import SIMPLE_PAYWALL_1M_29_99_1W_4_99 from './paywall/SIMPLE_PAYWALL_1M_29_99_1W_4_99'
import SIMPLE_PAYWALL_1M_29_99_1W_5_00 from './paywall/SIMPLE_PAYWALL_1M_29_99_1W_5_00'
import SIMPLE_PAYWALL_1M_29_99_1M_17_99 from './paywall/SIMPLE_PAYWALL_1M_29_99_1M_17_99'
import SIMPLE_PAYWALL_3M_59_99_3M_29_99 from './paywall/SIMPLE_PAYWALL_3M_59_99_3M_29_99'
import SIMPLE_PAYWALL_1M_29_99_1M_44_00 from './paywall/SIMPLE_PAYWALL_1M_29_99_1M_44_00'
import SIMPLE_PAYWALL_1M_29_95_1M_43_95 from './paywall/SIMPLE_PAYWALL_1M_29_95_1M_43_95'
import SIMPLE_PAYWALL_1M_29_99_1M_43_99 from './paywall/SIMPLE_PAYWALL_1M_29_99_1M_43_99'

import SIMPLE_PAYWALL_1M_10_00_1M_10_00 from './commitment/SIMPLE_PAYWALL_1M_10_00_1M_10_00'
import SIMPLE_PAYWALL_1M_29_99_3M_15_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_3M_15_00'
import SIMPLE_PAYWALL_1M_29_99_3M_14_95 from './commitment/SIMPLE_PAYWALL_1M_29_99_3M_14_95'
import SIMPLE_PAYWALL_1M_29_99_3M_14_99 from './commitment/SIMPLE_PAYWALL_1M_29_99_3M_14_99'
import SIMPLE_PAYWALL_1M_29_99_6M_11_95 from './commitment/SIMPLE_PAYWALL_1M_29_99_6M_11_95'
import SIMPLE_PAYWALL_1M_29_99_10M_12_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_10M_12_00'
import SIMPLE_PAYWALL_1M_29_99_3M_19_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_3M_19_00'
import SIMPLE_PAYWALL_1M_29_99_6M_12_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_6M_12_00'
import SIMPLE_PAYWALL_1M_29_99_6M_11_99 from './commitment/SIMPLE_PAYWALL_1M_29_99_6M_11_99'

import SIMPLE_CANCELLATION_1M_29_99_1M_17_99 from './cancellation/SIMPLE_CANCELLATION_1M_29_99_1M_17_99'
import SIMPLE_CANCELLATION_3M_59_99_3M_29_99 from './cancellation/SIMPLE_CANCELLATION_3M_59_99_3M_29_99'
import SIMPLE_CANCELLATION_9M_79_2_9M_99 from './cancellation/SIMPLE_CANCELLATION_9M_79_2_9M_99'

import SIMPLE_UPGRADE_3M_59 from './upgrade/SIMPLE_UPGRADE_3M_59'
import SIMPLE_UPGRADE_3M_47_2 from './upgrade/SIMPLE_UPGRADE_3M_47_2'
import SIMPLE_UPGRADE_6M_79 from './upgrade/SIMPLE_UPGRADE_6M_79'
import SIMPLE_UPGRADE_6M_63_2 from './upgrade/SIMPLE_UPGRADE_6M_63_2'
import SIMPLE_UPGRADE_9M_99 from './upgrade/SIMPLE_UPGRADE_9M_99'
import SIMPLE_UPGRADE_9M_79_2 from './upgrade/SIMPLE_UPGRADE_9M_79_2'

// IS_FEMALE_ACTIVITY_NEW_PLANS_EXP
import SIMPLE_PREMIUM_1M_38_95_1W_6_93 from './experiments/paywall/SIMPLE_PREMIUM_1M_38_95_1W_6_93'
import SIMPLE_PREMIUM_1M_38_95_1M_15_19 from './experiments/paywall/SIMPLE_PREMIUM_1M_38_95_1M_15_19'
import SIMPLE_PREMIUM_3M_66_65_3M_25_99 from './experiments/paywall/SIMPLE_PREMIUM_3M_66_65_3M_25_99'
import SIMPLE_PREMIUM_1M_38_95_1W_17_77 from './experiments/paywall/SIMPLE_PREMIUM_1M_38_95_1W_17_77'
import SIMPLE_PREMIUM_1M_38_95 from './experiments/paywall/SIMPLE_PREMIUM_1M_38_95'
import SIMPLE_PREMIUM_3M_66_65 from './experiments/paywall/SIMPLE_PREMIUM_3M_66_65'

import SIMPLE_PAYWALL_1M_29_99_1M_29_99 from './paywall/SIMPLE_PAYWALL_1M_29_99_1M_29_99'
import SIMPLE_PAYWALL_1M_29_99_1W_9_99 from './paywall/SIMPLE_PAYWALL_1M_29_99_1W_9_99'
import SIMPLE_PAYWALL_3M_59_99_3M_59_99 from './paywall/SIMPLE_PAYWALL_3M_59_99_3M_59_99'

// IS_APPLE_PAY_AS_DEFAULT_CTA_EXP
import SIMPLE_PAYWALL_1M_29_99_1W_3_99 from './experiments/paywall/SIMPLE_PAYWALL_1M_29_99_1W_3_99'
import SIMPLE_PAYWALL_1M_29_99_1M_14_39 from './experiments/paywall/SIMPLE_PAYWALL_1M_29_99_1M_14_39'
import SIMPLE_PAYWALL_3M_59_99_3M_23_99 from './experiments/paywall/SIMPLE_PAYWALL_3M_59_99_3M_23_99'

// IS_FEMALE_ACTIVITY_NEW_UPGRADES_EXP
import SIMPLE_UPGRADE_3M_53_97 from './experiments/upgrade/SIMPLE_UPGRADE_3M_53_97'
import SIMPLE_UPGRADE_3M_44_97 from './experiments/upgrade/SIMPLE_UPGRADE_3M_44_97'
import SIMPLE_UPGRADE_6M_77_95 from './experiments/upgrade/SIMPLE_UPGRADE_6M_77_95'
import SIMPLE_UPGRADE_6M_107_95 from './experiments/upgrade/SIMPLE_UPGRADE_6M_107_95'
import SIMPLE_UPGRADE_9M_79_95 from './experiments/upgrade/SIMPLE_UPGRADE_9M_79_95'
import SIMPLE_UPGRADE_9M_89_97 from './experiments/upgrade/SIMPLE_UPGRADE_9M_89_97'

// IS_COMMITMENT_PLANS_WITH_CONSENT_EXP
import SIMPLE_PREMIUM_1M_30 from './experiments/paywall/SIMPLE_PREMIUM_1M_30'
import SIMPLE_PAYWALL_1M_29_99_3M_30_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_3M_30_00'
import SIMPLE_PAYWALL_1M_29_99_6M_30_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_6M_30_00'

// IS_COMMITMENT_PLANS_MALE_EXP
import SIMPLE_PAYWALL_1M_29_99 from './experiments/paywall/SIMPLE_PAYWALL_1M_29_99'

export default {
	SIMPLE_PAYWALL_1M_29_99_1W_3_99,
	SIMPLE_PAYWALL_1M_29_99_1W_4_99,
	SIMPLE_PAYWALL_1M_29_99_1W_5_00,
	SIMPLE_PAYWALL_1M_29_99_1M_14_39,
	SIMPLE_PAYWALL_1M_29_99_1M_17_99,
	SIMPLE_PAYWALL_3M_59_99_3M_23_99,
	SIMPLE_PAYWALL_3M_59_99_3M_29_99,
	SIMPLE_PAYWALL_1M_29_99_1M_44_00,
	SIMPLE_PAYWALL_1M_29_95_1M_43_95,
	SIMPLE_PAYWALL_1M_29_99_1M_43_99,

	SIMPLE_PAYWALL_1M_29_99_3M_15_00,
	SIMPLE_PAYWALL_1M_10_00_1M_10_00,
	SIMPLE_PAYWALL_1M_29_99,
	SIMPLE_PAYWALL_1M_29_99_3M_14_95,
	SIMPLE_PAYWALL_1M_29_99_3M_14_99,
	SIMPLE_PAYWALL_1M_29_99_6M_11_95,
	SIMPLE_PAYWALL_1M_29_99_10M_12_00,
	SIMPLE_PAYWALL_1M_29_99_3M_19_00,
	SIMPLE_PAYWALL_1M_29_99_6M_12_00,
	SIMPLE_PAYWALL_1M_29_99_6M_11_99,

	SIMPLE_CANCELLATION_1M_29_99_1M_17_99,
	SIMPLE_CANCELLATION_3M_59_99_3M_29_99,
	SIMPLE_CANCELLATION_9M_79_2_9M_99,

	SIMPLE_UPGRADE_3M_59,
	SIMPLE_UPGRADE_3M_47_2,
	SIMPLE_UPGRADE_6M_79,
	SIMPLE_UPGRADE_6M_63_2,
	SIMPLE_UPGRADE_9M_99,
	SIMPLE_UPGRADE_9M_79_2,

	SIMPLE_PAYWALL_1M_29_99_1M_29_99,
	SIMPLE_PAYWALL_1M_29_99_1W_9_99,
	SIMPLE_PAYWALL_3M_59_99_3M_59_99,

	SIMPLE_PREMIUM_1M_38_95_1W_6_93,
	SIMPLE_PREMIUM_1M_38_95_1M_15_19,
	SIMPLE_PREMIUM_3M_66_65_3M_25_99,
	SIMPLE_PREMIUM_1M_38_95_1W_17_77,
	SIMPLE_PREMIUM_1M_38_95,
	SIMPLE_PREMIUM_3M_66_65,

	SIMPLE_UPGRADE_3M_53_97,
	SIMPLE_UPGRADE_3M_44_97,
	SIMPLE_UPGRADE_6M_77_95,
	SIMPLE_UPGRADE_6M_107_95,
	SIMPLE_UPGRADE_9M_79_95,
	SIMPLE_UPGRADE_9M_89_97,

	SIMPLE_PREMIUM_1M_30,
	SIMPLE_PAYWALL_1M_29_99_3M_30_00,
	SIMPLE_PAYWALL_1M_29_99_6M_30_00,
}
