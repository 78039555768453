export default {
	id: 'SIMPLE_PAYWALL_1M_29_99_6M_12_00',
	providers: {
		paltaMap: {
			USD: 'simple_premium_comm_1m_30_6m_12_usd',
			EUR: 'simple_premium_comm_1m_29_99_6m_12_eur',
			GBP: 'simple_premium_comm_1m_29_99_6m_12_gbp',
			CAD: 'simple_premium_comm_1m_49_99_6m_20_cad',
			AUD: 'simple_premium_comm_1m_49_99_6m_20_aud',
			DKK: 'simple_premium_comm_1m_259_6m_104_dkk',
			SEK: 'simple_premium_comm_1m_399_6m_159_sek',
			NOK: 'simple_premium_comm_1m_399_6m_159_nok',
			BRL: 'simple_premium_comm_1m_199_90_6m_79_90_brl',
			MXN: 'simple_premium_comm_1m_499_6m_199_mxn',
		},
	},
	trial: {
		days: 30,
		priceMap: {
			USD: 12,
			EUR: 12,
			GBP: 12,
			CAD: 20,
			AUD: 20,
			DKK: 104,
			SEK: 159,
			NOK: 159,
			BRL: 79.9,
			MXN: 199,
		},
		originalPriceMap: {
			USD: 29.99,
			EUR: 29.99,
			GBP: 29.99,
			CAD: 49.99,
			AUD: 49.99,
			DKK: 259,
			SEK: 399,
			NOK: 399,
			BRL: 199.9,
			MXN: 499,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 29.99,
			EUR: 29.99,
			GBP: 29.99,
			CAD: 49.99,
			AUD: 49.99,
			DKK: 259,
			SEK: 399,
			NOK: 399,
			BRL: 199.9,
			MXN: 499,
		},
	},
	options: ($t) => ({
		commitment: true,
		commitmentPeriodMonths: 6,
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.special_upsale.upgrade_durations.6'), // 6-month
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
	}),
}
